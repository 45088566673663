.homeButton {
    /* background-color: red; */
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 150px;
    height: 40px;
    background-image: url('../../assets/images/eliteAltCoin.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.mobileLogo {
    width: 30px;
    height: 30px;
    background-image: url('../../assets/images/eliteAltCoin-mini.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 16px;
}

.navbarWrapper {
    background-color: var(--primary);
    box-shadow: 0px 0px 0px;
    padding: 0px 8px 0px 8px;
    display: flex;
    height: 10vh;
}

.hamburgerMenu {
    margin-top: 8px;
    width: 30px;
    height: 30px;
    background-image: url('../../assets/images/hamburgerMenu.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.signinButton {
    /* width: 84.6px !important; */
    border-color: white !important;
    margin-left: 16px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    color: white !important;
}

.signinButton2 {
    /* width: 84.6px !important; */
    border-color: black !important;
    margin-left: 8px !important;
    margin-bottom: 8px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    color: white !important;
}

.signupButton {
    background-color: var(--button1) !important;
    margin-Left: 8px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    color: white !important;
}