.profileWrapper {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.profileContainer {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.profileTitleContainer {
    text-align: left;
    padding: 16px;
    color: var(--primary);
    background-color: var(--lightBlue);
}

.profileTitle {
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
}

.inputContainer {
    padding: 16px;
}

.nameInputContainer p,
.emailInputContainer p,
.walletPassInputContainer p {
    font-size: 16px;
    font-weight: 700;
    opacity: 0.8;
}

.nameInputContainer div,
.emailInputContainer div,
.walletPassInputContainer div {
    width: 50%;
}

.nameInputContainer,
.walletPassInputContainer,
.emailInputContainer {
    display: flex;
}

.profileInput {
    font-size: 16px;
    height: 50px;
    width: 95%;
    /* border-radius: 4px; */
    border: 1px solid #888;
    color: #888;
    padding-Left: 4px;
    /* border-radius: 100px; */
    padding-left: 12px;
}

.passwordContainer {
    width: 100% !important;
    display: flex;
}

.editButtonContainer {
    width: 20% !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: 16px;

}

.editButton {
    color: var(--white) !important;
    background-color: var(--button1) !important;
    border-radius: 0px !important;
    height: 50px;
    width: 100%;
}

.rightInput {
    margin-left: 16px;
}

@media (max-width:1300px) {
    .profileWrapper {
        height: auto;
    }

    .profileContainer {
        margin-top: 32px;
        margin-bottom: 32px;
    }
}

@media (max-width:400px) {
    .profileTitleContainer {
        margin-bottom: 32px;
    }

    .nameInputContainer,
    .walletPassInputContainer,
    .emailInputContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nameInputContainer div,
    .emailInputContainer div,
    .walletPassInputContainer div {
        width: 90%;
    }

    .passwordContainer {
        flex-direction: column;
    }

    .profileInput {
        width: 100%;
    }

    .rightInput {
        margin-left: 0px;
    }

    .editButtonContainer {
        margin-top: 16px;
        width: 100% !important;
    }

}