.benifitsWrapper {
    padding: 100px 0px;
}

.benifitsTitle p {
    margin-top: 0;
    color: var(--black);
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
}

.benifitContainer {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 16px !important;
    margin-top: 24px;
    margin-bottom: 32px;
    margin-right: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.benifitTitle {
    font-size: 18px;
    color: var(--primary);
    margin-left: 16px;
    font-weight: bolder;
}