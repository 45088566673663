.footerWrapper {
    margin-bottom: 0;
    height: 9vh;
    background-color: var(--background2);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.footerSectionOneContainer,
.footerSectionTwoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.footerSectionOneLinks,
.footerSectionTwoLinks {
    display: flex;
    color: var(--black);
    opacity: 0.8;
    cursor: pointer;
    text-align: center;
}

.footerLogo {
    width: 150px;
    height: 25px;
    background-image: url('../../assets/images/allroundcryptologo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.socialMediaContainer {
    display: flex;
}

.socialMediaContainer div {
    margin-right: 16px;
}

@media (max-width:700px) {
    .footerSectionOneContainer {
        flex-direction: column;
    }

    .floatingContainer p {
        font-size: 2rem;
    }
}

@media (max-width:500px) {
    .footerSectionTwoContainer {
        flex-direction: column;
    }

    .socialMediaContainer {
        margin-top: 16px;
    }

    .floatingContainer {
        height: 30vh;
    }

    .floatingContainer p {
        margin-bottom: 0;
        font-size: 1.7rem;
    }
}

@media (max-width:360px) {
    .floatingContainer p {
        padding-left: 4px;
        padding-right: 4px;
    }

    .createAccount {
        font-size: 16px !important;
    }
}

@media (max-width:335px) {
    .floatingContainer p {
        font-size: 1.5rem;
    }
}