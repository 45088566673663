.cryptocurrencyWrapper {
    width: 100%;
    height: auto;
    background-color: var(--background2);
    padding: 32px 0px;
}



.paragraphBody p {
    font-size: 18px;
    line-height: 40px;
}

@media (max-width:900px) {
    .cryptocurrencyImageContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .paragraphContainer {
        text-align: center;
        padding: 48px 24px;
    }
}

@media (max-width: 500px) {
    .cryptocurrencyImageContainer img {
        width: 100%;
    }

    .paragraphTitle h1 {
        font-size: 26px;
    }

    .paragraphBody p {
        font-size: 16px;
        line-height: 30px;
    }
}

@media (max-width: 400px) {
    .paragraphBody p {
        font-size: 14px;
        line-height: 26px;
    }

    .paragraphContainer {
        padding: 48px 0px;
    }
}