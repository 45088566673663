.SignupWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
}

.SignupContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SignupCard {
    position: relative;
    padding: 24px;
    width: 70%;
    background-color: var(--card);
    border-radius: 10px;
}

.SignupContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SigninTitleContainer {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.SignupArrowBack {
    position: absolute;
    top: 7%;
    left: 17%;
}

form {
    width: 70% !important;
}

.divSubmitButton {
    width: 100% !important;

}

.submitButton {
    padding: 16px !important;
    width: 100% !important;
    background-color: var(--button1) !important;
    border: none !important;
}

.date {
    background-color: var(--card);
    height: 50px;
    border-radius: 10px;
    width: 95%;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.295);
    color: rgba(0, 0, 0, 0.6);
    padding-left: 8px;
    cursor: pointer
}

.phoneNumberContainer {
    width: 95% !important;
    border: 1px solid rgba(0, 0, 0, 0.295) !important;
    padding: 8px !important;
    border-radius: 10px !important;

}

.phoneNumber {
    border-radius: 10px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}

.css-1vv4lmi::before {
    border-bottom: none !important;
}


.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border-radius: 10px !important;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before,
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
}

.SideSigninSignupContainer {
    width: 70%;
}

.SideSigninSignup {
    color: #a4a4a4;
}

.boxes {
    margin-bottom: 12px;
    margin-top: 24px;
    display: flex;
}

@media (max-width:1250px) {
    .SignupCard {
        width: 70%;
    }

    .SideSignupContainer h1 {
        font-size: 40px;
    }

    .SideSignupContainer p {
        font-size: 20px;
    }

}

@media (max-width:1050px) {

    form,
    .SideSigninSignupContainer {
        width: 80% !important;
    }

    .SideSignupContainer h1 {
        font-size: 35px;
    }

    .SideSignupContainer p {
        font-size: 15px;
    }

}

@media (max-width:600px) {
    .SignupCard {
        width: 80%;
    }

    form,
    .SideSigninSignupContainer {
        width: 100% !important;
    }
}

@media (max-width:450px) {
    form {
        width: 100% !important;
    }
}