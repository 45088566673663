.bodyWrapper {
    height: auto;
    background-color: var(--primary);
}

.bodyContainer {
    padding: 128px 32px 128px 32px;
    /* margin-top: 64px; */
    display: flex;
    flex-direction: column;
    color: #fff;
}

.bottom {
    color: white !important;
    border-bottom: 2px white solid !important;
    border-radius: 0px !important;
    text-transform: capitalise !important;
    box-shadow: 0px !important;
    margin-left: 64px !important;
}

.bodyButton {
    color: #fff !important;
    background: linear-gradient(to right, #1FA3A3, #1F349C) !important;
    padding: 12px 32px 12px 32px !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
}

.bodyButton2 {
    color: #fff !important;
    background: linear-gradient(to right, #1FA3A3, #1F349C) !important;
    padding: 6px 16px 6px 16px !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bodyTitle {
    font-size: 48px;
    font-weight: 700;
    margin: 0px 0px 16px 0px;
}

.bodyDescription {
    /* margin-top: 0; */
    margin-bottom: 48px;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.cryptoBackground {
    width: 100%;
    height: 100%;
}

@media (max-width:1250px) {
    .bodyContainer {
        padding: 100px 32px 0px 32px;
    }
}

@media (max-width:950px) {
    .bodyTitle {
        font-size: 2.8rem;
    }

    .bodyDescription {
        font-size: 20px;
    }
}

@media (max-width:900px) {
    .cryptoBackground {
        width: 50%;
    }

    .cryptoBackgroundContainer {
        margin-top: 64px;
    }

    .bodyContainer,
    .cryptoBackgroundContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media (max-width:685px) {
    .bodyTitle {
        font-size: 3rem;
    }

    .bodyDescription {
        font-size: 1.3rem;
    }
}

@media (max-width:596px) {
    .bodyTitle {
        font-size: 2.5rem;
    }

}

@media (max-width:414px) {
    .bodyTitle {
        font-size: 2.1rem;
    }

    .bodyContainer {
        padding: 64px 32px 80px 32px;
    }

    .bodyDescription {
        font-size: 1.1rem;
    }
}

@media (max-width:470px) {
    .cryptoBackground {
        width: 80%;
    }

    .bodyContainer {
        padding: 128px 32px 64px 32px;

    }

    .buttonsContainer {
        flex-direction: column;
    }

    .bottom {
        margin-left: 0px !important;
        margin-top: 16px !important;
    }

    .createAccount {
        margin-top: 16px !important;
    }
}