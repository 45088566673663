.coinTableWrapper {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.convertSentence {
    width: 100% !important;
    color: var(--cardSubTitle) !important;
    margin-top: 6px;
    margin-bottom: 6px;
    font-weight: bolder;
    text-align: center !important;
    font-size: 20px !important;
}

.coinTableContainer {
    height: 50vh;
    overflow-y: auto;
    margin-top: 48px;
}

.coinTableContainer::-webkit-scrollbar {
    width: 7px;
}

.coinTableContainer::-webkit-scrollbar-track {
    background: #fff;
    margin-top: 80px;
    margin-bottom: 30px;
}

.coinTableContainer::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 100px !important;
}

.coinTableContainer::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}

.coinTable {
    width: 100%;
    position: relative !important;
    border-collapse: collapse !important;
}

.cryptoTableCell {
    margin-top: 0 !important;
    position: sticky !important;
    top: 0px !important;
    background-color: var(--lightBlue) !important;
    color: var(--primary) !important;
    font-weight: bold !important;
}

.cryptoTableCell:nth-child(1) {
    border-radius: 0px 0px 0px 0px;
}

.cryptoTableCell:nth-child(6) {
    border-radius: 0px 0px 0px 0px;
}

.cryptoMarketLogoName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cryptoMarketLogo {
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 4px;
}

.cryptoMarketName {
    font-size: 18px;
    margin: 0;
    margin-left: 8px;
    color: black;
}

.cryptoMarketName span {
    text-transform: uppercase;
}

.negChange {
    margin: 0;
    color: red;
    font-weight: 600;
}

.posChange {
    font-weight: 600;
    margin: 0;
    color: green
}

.search {
    margin-top: 24px;
    /* background-color: red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search input {
    width: 50%;
    height: 50px;
    font-size: 18px;
    padding-left: 16px;
    border: 1px solid #c4c4c4;
    border-radius: 100px;
}

.MuiTableCell-root {
    border-bottom: none !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
}