.cryptoDetailWrapper {
    background-color: var(--lightBlue);
    border-radius: 24px;
    padding: 24px;
    border-right: 8px solid #EAF4FE;
}

.cryptoDetailContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cryptoDetailContainer div {
    margin-bottom: 8px;
}

.cryptoWrapper {
    height: auto;
    padding: 48px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background2);
}


.cryptoLogo {
    width: 70px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 4px;
}

.cryptoName {
    font-size: 20px;
    margin: 0;
    color: var(--primary);
    font-weight: bold !important;
}

.cryptoCurrency {
    font-size: 18px;
    margin: 0;
    color: black;
}


.cryptoName span {
    text-transform: uppercase;
}

.cryptoBodyContainer {
    display: flex;
    flex-direction: column;

}

.negChange {
    padding-top: 4px;
    padding-left: 4px;
    margin: 0;
    color: red;
    font-size: 14px;
}

.posChange {
    padding-top: 4px;
    padding-left: 4px;
    margin: 0;
    color: green;
    font-size: 14px;

}

.volumeValue {
    padding-top: 4px;
    opacity: 0.8;
    font-size: 12px;
    margin: 0;
    color: black;
}

@media (max-width:1055px) {

    .cryptoName,
    .cryptoCurrency {
        font-size: 17px;
    }
}

@media (max-width:900px) {
    .cryptoDetailWrapper {
        margin-bottom: 16px;
    }
}

@media (max-width:350px) {
    .cryptoLogo {
        width: 40px;
    }

    .cryptoName,
    .cryptoCurrency {
        font-size: 16px;
    }

    .negChange {
        font-size: 12px;
    }

    .posChange {
        font-size: 12px;
    }

    .volumeValue {
        font-size: 10px;
    }
}