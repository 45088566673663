.widthDrawWrapper {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.withDrawContainer {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.formWrapper {
    padding: 16px;
}

.withDrawTitleContainer {
    width: 93.5%;
    background-color: var(--lightBlue);
    margin: 0;
    padding: 16px;
    text-align: left;
    color: var(--primary);
}

.myWalletContainer {
    width: 50% !important;
    margin-right: 16px;
}

.withdrawAmountContainer {
    margin-bottom: 24px;
}

.css-igs3ac {
    border-radius: 0px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 0px !important;
}

.myWalletContainer p,
.toWalletContainer p,
.withdrawAmountContainer p {
    font-weight: 600;
    opacity: 0.8;
}

.withdrawTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.maxAmount {
    width: 40% !important;
    border-radius: 0px !important;
    background-color: var(--button1) !important;
    margin-left: 8px !important;
}

.withdrawSubmitButton {
    margin-top: 12px !important;
    border-radius: 0px !important;
    background-color: var(--button1) !important;
    height: 48px;
}

.disabledWithdrawSubmitButton {
    width: 100% !important;
    margin-top: 12px !important;
    border-radius: 0px !important;
    height: 48px;
}

.successMessageContainer p {
    text-align: center;
    width: 100%;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
    background: rgba(255, 122, 89, .5);
    height: 55px;
    border-radius: 0px;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}