:root {
    --primary: #004FF3;
    --secondary: #47817F;
    --secondary2: #4B4A54;
    --background2: #EAF4FE;
    --button1: #ff3890;
    --white: #fff;
    --black: #000;
    --lightBlack: #1C1A27;
    --lightBlue: #C8FCEA;
    --card: #f1f1f1;
    --cardSubTitle: #302d4a;
}

@font-face {
    font-family: lato;
    src: url(../src/assets/fonts/Lato-Regular.ttf);
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 100px !important;
    /* height: 50px !important; */
}

body {
    margin: 0px;
    font-family: lato;
}

body::-webkit-scrollbar {
    display: none;
}