.featuresWrapper {
    padding: 100px 0px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
}

.featuresTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.featuresTitle p {
    margin: 0;
    font-size: 36px;
    font-weight: bolder;
    text-align: center;
}

.featuresSubTitle p {
    font-size: 20px;
    text-align: center;
    opacity: 0.5;
}

.featuresContainer {
    margin-top: 128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.featuresCardsContainer {
    width: 300px;
    height: 300px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.featuresCardIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.featuresCardTitleContainer p {
    color: var(--black);
    font-size: 22px;
    font-weight: bolder;
    text-align: center;
}

.featuresCardTextContainer {
    text-align: center;
}

.featuresCardTextContainer p {
    color: var(--black);
    text-align: center;
    font-size: 18px;
    line-height: 25px;
}

@media (max-width:415px) {
    .featuresTitle p {
        font-size: 50px;
    }
}

@media (max-width:350px) {
    .featuresTitle p {
        font-size: 40px;
    }

    .featuresSubTitle p {
        font-size: 18px;
    }
}