/* h2 {
    color: var(--primary);
} */

.emailTo {
    color: var(--primary);
    font-weight: 700;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0 !important;
}

.textContact {
    color: var(--primary);
    text-align: center;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper {
    border-radius: 0px !important;
}

.ContactUsTitleContainer {
    background-color: var(--lightBlue);
    padding: 16px;
    text-align: left;
}

.ContactUsTitleContainer h2 {
    color: var(--primary);
    margin: 0;
}

.ContactUsSubmitButton {
    margin-top: 12px !important;
    width: 100% !important;
    border-radius: 0px !important;
    background-color: var(--button1) !important;
    height: 48px !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0px !important;
}